<template>
  <FormModal class="form-modal--intro form-modal--primary">
    <template #body>
      <div class="form-modal__logo-container">
        <InlineSvg class="form-modal__logo" src="images/logo.svg" :alt="concept" />
      </div>
      <div class="form-modal__title">
        We gaan je nu een paar vragen stellen. Hierdoor kunnen we hoveniers vinden die bij jou passen.
      </div>
    </template>

    <template #footer>
      <t-btn-link
        id="footerNextButton"
        class="footer__next-btn"
        to="/offertes-aanvragen/type-opdracht"
      >
        Ga verder
      </t-btn-link>
    </template>
  </FormModal>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'

export default {
  components: {
    FormModal,
    InlineSvg
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Start vragenlijst',
      headDescription: 'Interesse in een offerte voor een hovenier? Vergelijk hoveniers op Hoveniervergelijker.nl. Je ontvangt gratis en snel offertes van specialisten uit jouw regio.',
      path: '/offertes-aanvragen/start',
      progressValue: 0,
      checkoutStep: 1
    }
  }
}
</script>
